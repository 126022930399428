/* eslint-disable react/no-danger, react/prop-types */
import React from 'react'
import { shape } from 'prop-types'
import { graphql } from 'gatsby'
import { motion } from 'framer-motion'
import SEO from '@/components/Seo'
import { fade } from '@/utils/transitions'
import { cleanProps } from '@/utils'
import Blocks from '@/utils/Blocks'
import useConstant from '@/hooks/useConstant'
/* eslint-disable no-unused-vars */
import { pageQuery } from '@/queries'
/* eslint-enable no-unused-vars */
import * as T from '@/types'
import PageHeading from '@/components/Tracking/PageHeading'
import CTAButtonBlock from '@/components/CTAButtonBlock'


function LandingPage({
	pageContext: { media, wordpress_id },
	data: {
		page: { blocks, yoast, featured_media }
	}
}) {
	const data = useConstant(() => cleanProps(blocks, media))
	
	const pageHeadingData = data.find(block => block.type === 'acf/page-heading')
	const filteredData = data.filter(block => block.type !== 'acf/page-heading')
	
	return (
		<motion.div
			variants={fade}
			initial="initial"
			animate="enter"
			exit="exit"
		>
			<SEO {...yoast} image={featured_media} />
			{ pageHeadingData && <PageHeading landingPage {...pageHeadingData} /> }
			<Blocks data={filteredData} media={media} currentPage={wordpress_id} />
		</motion.div>
	)
}

LandingPage.propTypes = {
	pageContext: T.pageContext.isRequired,
	data: shape({
		page: T.page.isRequired
	})
}

export const query = graphql`
	query LandingPageQuery($path: String!) {
		page: wordpressPage(path: { eq: $path }) {
			...pageQuery
		}
	}
`

export default LandingPage
